import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img, { ImgProps } from "../components/Img"
import Link, { LinkProps } from "./Link"

interface PageHeaderProps {
  headerNav1: LinkProps[],
  headerNav2: LinkProps[],
  headerLogo: LinkProps & {
    img: ImgProps
  }
}

const PageHeaderContent: FC<PageHeaderProps> = ({
  headerNav1,
  headerNav2,
  headerLogo
}) => (
  <header
    className="p-4 text-center fixed inset-x-0 top-0 z-20 border-solid border-b-2 xs:px-2"
    style={{ borderColor: "rgba(255, 255, 255, 0.12)" }}
  >
    <nav className="flex items-center justify-between max-w-5xl mx-auto w-full sm:justify-center">
      <div className="sm:order-2">
        {headerNav1.map(({
          to,
          text
        }) => (
          <Link
            key={to}
            to={to}
            className="page-header-link first:ml-0 sm:mx-2 sm:text-sm xs:mx-1"
          >
            {text}
          </Link>
        ))}
      </div>

      <Link
        to={headerLogo.to}
        className="sm:order-1 sm:mr-4 text-center block xs:mr-2"
      >
        <Img
          className="w-14 mx-auto sm:w-10 xs:w-6"
          src={headerLogo.img.src}
          alt={headerLogo.img.alt}
        />

        <p className="text-pr0 font-semibold tracking-x-ultra text-lg sm:hidden">
          {headerLogo.text}
        </p>
      </Link>

      <div className="sm:order-3">
        {headerNav2.map(({
          to,
          text
        }) => (
          <Link
            key={to}
            to={to}
            className="page-header-link last:mr-0 md:mx-2 sm:text-sm xs:mx-1"
          >
            {text}
          </Link>
        ))}
      </div>
    </nav>
  </header>
)

const PageHeader: FC = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query PageHeader {
        markdownRemark(
          frontmatter: {
            component: {
              eq: "PageHeader"
            }
          }
        ) {
          frontmatter {
            headerNav1 {
              to
              text
            }
            headerNav2 {
              to
              text
            }
            headerLogo {
              to
              text
              img {
                src {
                  childImageSharp {
                    fluid(maxHeight: 60 quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
              }
            }
          }
        }
      }
    `
  )

  const {
    headerNav1,
    headerNav2,
    headerLogo
  } = markdownRemark.frontmatter

  return (
    <PageHeaderContent
      headerNav1={headerNav1}
      headerNav2={headerNav2}
      headerLogo={headerLogo}
    />
  )
}

export default PageHeader
