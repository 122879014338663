import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img, { ImgProps } from "../components/Img"
import Link, { LinkProps } from "./Link"
import cn from "../utils/cn"

const FooterLink: FC<any> = ({
  className,
  ...props
}) => (
  <div style={{ marginBottom: 4 }}>
    <Link className={cn("text-fg2", className)} {...props} />
  </div>
)

interface SocialMediaNavItem {
  to: string,
  icon: ImgProps
}

type Nav = LinkProps[]

interface FooterNav {
  label: string,
  nav: Nav
}

interface PageFooterContentProps {
  socialMediaNav: SocialMediaNavItem[],
  contactNav: Nav,
  nav1: FooterNav,
  nav2: FooterNav,
  nav3: FooterNav,
  logo: ImgProps,
  copyrightText: string
}

const PageFooterContent: FC<PageFooterContentProps> = ({
  socialMediaNav,
  contactNav,
  nav1,
  nav2,
  nav3,
  logo,
  copyrightText
}) => (
  <footer className="text-sm bg-bg1">
    <section className="w-full px-6 pt-20 pb-24 border-b-2 border-bg0 border-solid xs:pt-10 xs:pb-12">
      <div className="w-full max-w-4xl mx-auto flex justify-between flex-wrap">
        <nav
          role="navigation"
          className="mt-6 sm:w-1/2 xs:w-full xs:text-center xs:mb-3"
        >
          <Img
            src={logo.src}
            alt={logo.alt}
            className="mb-3 xs:mx-auto"
            style={{ width: 90 }}
          />

          {contactNav.map(({
            to,
            text
          }) => (
            <FooterLink
              key={to}
              to={to}
              target="_blank"
              rel="noopener"
            >
              {text}
            </FooterLink>
          ))}

          <nav
            role="navigation"
            className="flex mt-3 xs:justify-center"
          >
            {socialMediaNav.map(({
              to,
              icon
            }) => (
              <Link
                key={to}
                className="first:ml-0 ml-2 opacity-50 hover:opacity-100"
                target="_blank"
                rel="noopener"
                to={to}
                title={icon.alt}
              >
                <span className="hidden">
                  {icon.alt}
                </span>

                <Img
                  src={icon.src}
                  alt={icon.alt}
                  className="w-3"
                />
              </Link>
            ))}
          </nav>
        </nav>

        <nav
          role="navigation"
          className="mt-6 sm:w-1/2 xs:w-full xs:text-center xs:mb-3"
        >
          <h3 className="mb-3 text-base text-white tracking-widest font-semibold uppercase">
            {nav1.label}
          </h3>

          {nav1.nav.map(({
            to,
            text
          }) => (
            <FooterLink
              key={to}
              to={to}
            >
              {text}
            </FooterLink>
          ))}
        </nav>

        <nav
          role="navigation"
          className="mt-6 sm:w-1/2 xs:w-full xs:text-center xs:mb-3"
        >
          <h3 className="mb-3 text-base text-white tracking-widest font-semibold uppercase">
            {nav2.label}
          </h3>

          {nav2.nav.map(({
            to,
            text
          }) => (
            <FooterLink
              key={to}
              to={to}
            >
              {text}
            </FooterLink>
          ))}
        </nav>

        <nav
          role="navigation"
          className="mt-6 sm:w-1/2 xs:w-full xs:text-center"
        >
          <h3 className="mb-3 text-base text-white tracking-widest font-semibold uppercase">
            {nav3.label}
          </h3>

          {nav3.nav.map(({
            to,
            text
          }) => (
            <FooterLink
              key={to}
              to={to}
            >
              {text}
            </FooterLink>
          ))}
        </nav>
      </div>
    </section>

    <section className="py-4 text-center text-fg2">
      {copyrightText}
    </section>
  </footer>
)

const PageFooter: FC = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query PageFooter {
        markdownRemark(
          frontmatter: {
            component: {
              eq: "PageFooter"
            }
          }
        ) {
          frontmatter {
            socialMediaNav {
              to
              icon {
                src {
                  childImageSharp {
                    fluid(maxWidth: 36 quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
              }
            }
            contactNav {
              to
              text
            }
            nav1 {
              label
              nav {
                to
                text
              }
            }
            nav2 {
              label
              nav {
                to
                text
              }
            }
            nav3 {
              label
              nav {
                to
                text
              }
            }
            copyrightText
            logo {
              src {
                childImageSharp {
                  fluid(maxHeight: 60 quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    `
  )

  const {
    socialMediaNav,
    contactNav,
    nav1,
    nav2,
    nav3,
    copyrightText,
    logo
  } = markdownRemark.frontmatter

  return (
    <PageFooterContent
      socialMediaNav={socialMediaNav}
      contactNav={contactNav}
      nav1={nav1}
      nav2={nav2}
      nav3={nav3}
      copyrightText={copyrightText}
      logo={logo}
    />
  )
}

export default PageFooter
