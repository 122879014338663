import React, { CSSProperties } from "react"
import GatsbyImage, { FluidObject } from "gatsby-image"

export interface ImgProps {
  src: string | {
    extension?: string,
    publicURL?: string,
    childImageSharp: {
      fluid: FluidObject
    }
  },
  alt?: string,
  style?: CSSProperties,
  className?: string,
  imgStyle?: CSSProperties,
  loading?: "auto" | "lazy" | "eager",
  fadeIn?: boolean
}

const Img: React.FC<ImgProps> = (props) => {
  let img = null

  if(typeof props.src === "string") {
    img = <img
      src={props.src}
      alt={props.alt}
      style={props.style}
      className={props.className}
    />
  } else if (props.src.extension === "svg") {
    img = <img
      src={props.src.publicURL}
      alt={props.alt}
      style={props.style}
      className={props.className}
    />
  } else {
    img = <GatsbyImage
      fluid={props.src.childImageSharp.fluid}
      alt={props.alt}
      style={props.style}
      imgStyle={props.imgStyle}
      className={props.className}
      loading="eager"
      fadeIn={false}
    />
  }

  return img
}

export default Img
