import React, { FC } from "react"
import Helmet from "react-helmet"
import "../design/resets.css"
import "../design/styles.css"
import PageHeader from "./PageHeader"
import PageFooter from "./PageFooter"

const Layout: FC = ({ children }) => (
  <>
    <Helmet>
      <html lang="no" />
      <meta name="theme-color" content="#CEAD5F" />
      <link rel="canonical" href="https://ymro.no" />
      <link rel="icon" type="image/png" href="/uploads/ymro-favicon.png" />
      <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700&display=fallback" />

    </Helmet>

    <PageHeader />

    {children}

    <PageFooter />
  </>
)

export default Layout
